/* Run functions on document.ready */
$(document).ready(function(){

    // Google map for Contact/Offices page
    if (typeof google === 'object' && typeof google.maps === 'object') {
        setGMap('contact-map', '.marker', 'ROADMAP', 4, '49.620127', '-80.691793');
    }

    var msg = 'Like looking at code? Is this your passion? Send us an email along with your resume and your github username to jobs+code@adnetinc.com';
    console.log(msg);

    /* responsive imagemap */
    $('img[usemap]').rwdImageMaps();
    
    /* mobile-nav */
    var $mobile_nav_trigger = $('.mobile-nav-btn'),
		$content_wrapper = $('.main-body'),
		$navigation = $('header');

	//open-close lateral menu clicking on the menu icon
	$mobile_nav_trigger.on('click', function(e){
		e.preventDefault();
		
		$mobile_nav_trigger.toggleClass('is-clicked');
		$navigation.toggleClass('mobile-nav-is-open');
		$content_wrapper.toggleClass('mobile-nav-is-open').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
			// firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
			$('body').toggleClass('overflow-hidden');
		});
		$('.mobile-nav').toggleClass('mobile-nav-is-open');
		
		//check if transitions are not supported - i.e. in IE9
		if($('html').hasClass('no-csstransitions')) {
			$('body').toggleClass('overflow-hidden');
		}
	});

	// close menu when close button is clicked
	$('.mobile-nav ul.stock li.close a').on('click', function(e) {
		e.preventDefault();
		$mobile_nav_trigger.removeClass('is-clicked');
		$navigation.removeClass('mobile-nav-is-open');
		$content_wrapper.removeClass('mobile-nav-is-open').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
			$('body').removeClass('overflow-hidden');
		});
		$('.mobile-nav').removeClass('mobile-nav-is-open');
		//check if transitions are not supported
		if($('html').hasClass('no-csstransitions')) {
			$('body').removeClass('overflow-hidden');
		}
	});

	//close lateral menu clicking outside the menu itself
	$content_wrapper.on('click', function(event){
		if( !$(event.target).is('.mobile-nav-btn, .mobile-nav-btn span') ) {
			$mobile_nav_trigger.removeClass('is-clicked');
			$navigation.removeClass('mobile-nav-is-open');
			$content_wrapper.removeClass('mobile-nav-is-open').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
				$('body').removeClass('overflow-hidden');
			});
			$('.mobile-nav').removeClass('mobile-nav-is-open');
			//check if transitions are not supported
			if($('html').hasClass('no-csstransitions')) {
				$('body').removeClass('overflow-hidden');
			}

		}
	});

	//open (or close) submenu items in the lateral menu. Close all the other open submenu items.
	$('.has-sub > a').on('click', function(e){
		e.preventDefault();
		$(this).parent().toggleClass('sub-open').find('> .sub').slideToggle(200).parent('.has-sub').siblings('.has-sub').removeClass('sub-open').find('> .sub').slideUp(200);
	});

	/* Format tables and lists */
    var mainDiv = $('div.main-col');
    if (mainDiv.length){
        // Convert tables to uikit style table
        mainDiv.find('table').not('.exception').each(function(){
            var table = $(this);
            table.wrap('<div class="uk-overflow-container" />');
            //table.addClass('uk-table uk-table-striped');
        });
    }

$(window).setBreakpoints({
// use only largest available vs use all available
    distinct: true, 
// array of widths in pixels where breakpoints
// should be triggered
    breakpoints: [
        960
    ] 
});

$(window).bind('enterBreakpoint960',function() {
	// mobile nav closes when browser width extends
	$navigation.removeClass('mobile-nav-is-open');
	$content_wrapper.removeClass('mobile-nav-is-open');
	$('.mobile-nav').removeClass('mobile-nav-is-open');
	$mobile_nav_trigger.removeClass('is-clicked');
});

});

/* Run functions on window.load */
$(window).load(function(){

});